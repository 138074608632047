import { Link } from "gatsby"
import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { FaLinkedinIn, FaFacebookF, FaTwitter } from "react-icons/fa"
// import {  FaFacebookSquare, FaSquareXTwitter  } from "react-icons/fa6"
import { BsTwitterX } from "react-icons/bs"

import logo from "../../images/new-images/footer-white-logo.png"
import quickbase from "../../images/new-images/quickbase-footer.png"
import workato from "../../images/new-images/workato-footer.png"
import pipefy from "../../images/new-images/pipefy-footer.png"
import fb from "../../images/new-images/fb-icon.png"
import linkedin from "../../images/new-images/linkedin-icon.png"
import twitter from "../../images/new-images/twitter-logo.png"
import email from "../../images/new-images/email.png"
import phone from "../../images/new-images/phone-call.png"

const footer = props => {
  return (
    <>
      <footer
        className="site-footer section"
        style={{
          padding: "3rem 0",
          zIndex: 2,
          position: "relative",
        }}
      >
        <div className="container">
          {/* Top Horizontal Bar | Logo - Catchphrase --- Socials */}
          <div
            className="d-flex align-items-center flex-md-row flex-column justify-content-md-between justify-content-center"
            style={{
              width: "100%",
              paddingBottom: "2rem",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            <img
              placeholder="blurred"
              className="img-fluid"
              src={logo}
              alt="Quandary logo"
              // width={215}
              layout="constrained"
              loading="lazy"
            />

            <div
              className="d-flex align-items-center flex-md-row flex-column"
              style={{ gap: "1rem" }}
            >
              <p
                style={{
                  color: "#fff",
                  marginBottom: "0px",
                  textDecoration: "",
                }}
              >
                <i>From idea to application in weeks</i>
              </p>
              <ul
                className="list-unstyled"
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <li style={{ marginBottom: "0px" }}>
                  <a
                    href="tel:+7207391406"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#0D65A6",
                      padding: "8px",
                      borderRadius: "0.2rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    aria-label="Quandary Phone Number"
                  >
                    <img
                      placeholder="blurred"
                      className="img-fluid"
                      src={phone}
                      alt="Phone number"
                      // width={215}
                      layout="constrained"
                      loading="lazy"
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </a>
                </li>
                <li style={{ marginBottom: "0px" }}>
                  <a
                    href="mailto:info@quandarycg.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#0D65A6",
                      padding: "8px",
                      borderRadius: "0.2rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    aria-label="Quandary Email Address"
                  >
                    <img
                      placeholder="blurred"
                      className="img-fluid"
                      src={email}
                      alt="email address"
                      // width={215}
                      layout="constrained"
                      loading="lazy"
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="mt-4"
            style={{
              display: "flex",
              alignContent: "center",
              flexWrap: "wrap",
            }}
          >
            {/* Innovation */}
            <div
              className="mt-lg-0 mt-md-0 mb-3 mb-md-4 mb-lg-4 mb-xl-0 menu-mobile"
              style={{ width: "100%", maxWidth: "220px", padding: "0px 15px" }}
            >
              <p className="subheading">Innovation</p>
              <ul className="list-unstyled">
                <li>
                  <Link to="/innovation-analysis/">Innovation Analysis</Link>
                </li>
                <li>
                  <Link aria-label="link" to="/rapid-innovation/">
                    Rapid Innovation
                  </Link>
                </li>
                <li>
                  <Link aria-label="link" to="/software-asset-management/">
                    Software Asset Management
                  </Link>
                </li>
                <li className="list-size-tech">
                  <Link aria-label="link" to="/operationalize-ai/">
                    Operationalize AI
                  </Link>
                </li>
              </ul>
            </div>
            {/* Quickbase Consulting */}
            <div
              className="mt-lg-0 mt-md-0 mb-3 mb-md-4 mb-lg-4 mb-xl-0 menu-mobile"
              style={{ width: "100%", maxWidth: "250px", padding: "0px 15px" }}
            >
              <p className="subheading">Quickbase Consulting</p>
              <ul className="list-unstyled">
                <li>
                  <Link to="/custom-quickbase-application-development/">
                    Application Development
                  </Link>
                </li>
                <li>
                  <Link to="/quickbase-integration-services/">
                    Integration Services
                  </Link>
                </li>
                <li>
                  <Link to="/application-security-audit/">
                    Application Security Audit
                  </Link>
                </li>
                <li>
                  <Link to="/application-health-audit/">
                    Application Health Audit
                  </Link>
                </li>
                <li>
                  <Link to="/post-launch-enablement-services/">
                    Post Launch Enablement
                  </Link>
                </li>
                <li>
                  <Link to="/peaksuite/">peakSUITE</Link>
                </li>
                {/* <li>
                  <Link aria-label='link' to="/quandary-marketing/">Quandary Marketing</Link>
                </li>*/}
              </ul>
            </div>
            {/* Partners */}
            <div
              className="menu-mobile"
              style={{ width: "100%", maxWidth: "200px", padding: "0px 15px" }}
            >
              <p className="subheading">Partners</p>
              <ul className="list-unstyled">
                <li>
                  <Link to="/partners/quickbase/">Quickbase</Link>
                </li>
                <li>
                  <Link to="/partners/workato/">Workato</Link>
                </li>
                <li>
                  <Link to="/partners/pipefy/">Pipefy</Link>
                </li>
                <li>
                  <a href="/partners/trackvia/">Trackvia</a>
                </li>
              </ul>
            </div>
            {/* Our Work */}
            <div
              className="ml-lg-0 menu-mobile"
              style={{ width: "100%", maxWidth: "220px", padding: "0px 15px" }}
            >
              <p className="subheading">Our Work</p>
              <ul className="list-unstyled">
                {/* <li>
                  <Link to="/write-for-us/">Write For Us</Link>
                </li> */}
                <li>
                  <Link to="/knowledge-base/">Knowledge Base</Link>
                </li>
                <li>
                  <Link to="/blog/">Blog</Link>
                </li>
                <li>
                  <Link to="/case-studies/">Case Studies</Link>
                </li>
                <li>
                  <Link to="/faq/">FAQ</Link>
                </li>
              </ul>
            </div>
            {/* Location and Partner Logos */}
            <div
              className="mt-1 px-3 px-md-3 px-lg-3 px-xl-0 mt-3 mt-md-0 mt-lg-4 mt-xl-0"
              style={{ maxWidth: "270px" }}
            >
              {/* <p className="mb-0">
                6400 S Fiddlers Green Circle
                <br />
                Suite 250
                <br /> Greenwood Village, CO 80111
              </p> */}
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <img
                      placeholder="blurred"
                      src={quickbase}
                      alt="Gold Partner"
                      layout="constrained"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-3">
                    <img
                      placeholder="blurred"
                      src={workato}
                      alt="Workato Gold"
                      layout="constrained"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="pt-3">
                    <img
                      placeholder="blurred"
                      src={pipefy}
                      alt="Pipefy"
                      layout="constrained"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section id="copyrights" className="py-4">
        <div className="container">
          <div className="row">
            <div className="col text-lg-right text-md-center text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} Quandary Consulting Group. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default footer
